
export default class config
{
    static dominio = "http://localhost"
    static local = 0
    static apiKey = "AIzaSyDSdXLGo8gdPwNRBUA6pkU94X6i3KZLxLI"
}

if(process.env.NODE_ENV === "development"){
    config.dominio = "http://localhost"
}else if (window.location.host == 'hml.systemwtech.com.br'){
    config.dominio = "https://hml.systemwtech.com.br"
}else{
    config.dominio = "https://app.systemwtech.com.br"
    config.local = 1
}